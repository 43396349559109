import React, { FC } from "react";
import { Col, Container, Row } from "react-bootstrap";
import style from './Footer.module.scss';
import { useTranslation } from "react-i18next";
import mailImg from '../../assets/info-sky-echo-eu.png';

export type FooterProps = {};

export const Footer: FC<FooterProps> = () => {

    const { t } = useTranslation();

    return (
        <div className={`mx-auto mt-5 d-flex flex-column justify-content-center ${style.footer}`}>
            <Container fluid="lg">
                <div className={style.footerTop}>
                    <p className={style.footerText}>{t("Footer text") + " "}
                        <a target="_blank" href="https://discussion.urbanmeteo.org">{t("Discussion forum").toLocaleLowerCase()}</a>
                        {" " + t("Contact Us")}:
                    </p>
                    <img src={mailImg} />
                </div>

                <Row ><Col><hr /></Col></Row>

                <Row>
                    <Col className={style.footerBottomText}>
                        <p>{t('All right reserved')}</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
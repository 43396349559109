import React, { FC } from "react";
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from "gatsby";
import { SeoSiteMetadataQuery } from "../../typings/graphql-types";

export type SeoProps = {

};

export const SEO: FC<SeoProps> = ({ }) => {
    const data: SeoSiteMetadataQuery = useStaticQuery(
        graphql`
	        query SeoSiteMetadata {
	            site {
	                siteMetadata {
                        title
                        description
	                }
	            }
	        }
	    `
    );

    const title = data.site?.siteMetadata?.title;
    const description = data.site?.siteMetadata?.description;

    return (
        <Helmet
            defaultTitle={title ?? ''}
            titleTemplate={`%s | ${title}`}
            defer={false}
        >
            <meta name="description" content={description ?? ''} />
        </Helmet>
    )
};
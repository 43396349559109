import React, { FC, useRef, useEffect, useState, useContext } from "react";
import { PageProps } from "gatsby";
import { LocalizedLink as Link } from "gatsby-theme-i18n";
import { PagesUrls } from "../../typings/PagesUrls";
import { SEO } from "./SEO";
import { Nav, Navbar } from "react-bootstrap";
import "../sass/bootstrap.scss";
import logo from '../assets/logo.svg';
import { Footer } from "./footer/Footer";
import style from './Layout.module.scss';
import GBIcon from '../assets/GB-Reg.png';
import NLIcon from '../assets/NL-Reg.svg';
import { useLocalization } from "gatsby-theme-i18n";
import { useTranslation } from "react-i18next";
import { DataContext, DataProvider } from "../states/dataContext";
import { MapContext, MapProvider } from "../states/mapContext";

export type LayoutProps = {
    pageProps: PageProps;
};

export const Layout: FC<LayoutProps> = ({ children, pageProps }) => {

    const { locale, defaultLang } = useLocalization();
    const {resetHeatMapData, resetAllData, } = useContext(DataContext);
    const {resetEditorFeatures, } = useContext(MapContext)

    const { t } = useTranslation();

    const clearDataOnLanguageChange = (lang: "en" | "nl") => {
        if(locale !== lang){
            resetAllData();
            resetHeatMapData();
            resetEditorFeatures();
        };
    };

    return (
        <div>
            <SEO />
            <Navbar bg="light" expand="lg" variant="light" className={style.navbar}>
                <Navbar.Brand as={Link} to={PagesUrls.Home}>
                    <img
                        src={logo}
                        className="d-inline-block align-top"
                        alt="React Bootstrap logo"
                    />
                </Navbar.Brand>

                <Nav className={`justify-content-end align-items-center ${style.navbarLanguageContainer}`}>
                    <div style={{ marginRight: 15 }}>
                        <a href="https://discussion.sky-echo.eu" target="_blank"
                        >{t("Discussion forum")}</a>
                    </div>
                    <Link
                        to={pageProps.location.pathname.replace('/nl', "")}
                        language={defaultLang}
                        onClick={() => clearDataOnLanguageChange("en")}
                        className={locale === "en" ? style.countryFlagContainerActive : style.countryFlagContainerInactive}
                    >
                        <img src={GBIcon} className={style.countryFlag} />
                        <span className={style.countryCode}>{t('Language En')}</span>
                    </Link>

                    <div className={style.verticalLine} />

                    <Link
                        to={pageProps.location.pathname}
                        language={'nl'}
                        className={locale === "nl" ? style.countryFlagContainerActive : style.countryFlagContainerInactive}
                        onClick={() => clearDataOnLanguageChange("nl")}
                    >
                        <img src={NLIcon} className={style.countryFlag} />
                        <span className={style.countryCode}>{t('Language Nl')}</span>
                    </Link>
                </Nav>
            </Navbar>

            {children}

            <Footer />
        </div>

    );
};